@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

    #forgotPasswordText {
        padding-bottom: 1rem;
        text-align: left;
    }

    #email {
        height: 2.5rem;
    }
