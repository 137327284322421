@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  #EditProfile {
    padding-top: 10px;

    .back-section {
      position: relative;

      .back-section-inner {
        position: absolute;
        top: 15px;
        left: -80px;
        cursor: pointer;
      }

      @media screen and ( max-width: 900px ) {
        .back-section-inner {
          display: none;
        }
      }

      .fa-angle-left {
        font-size: 18pt;
        color: $dark_accent_color;
      }
    }

    .centeredContentBox {
      width: 80%;
      max-width: 800px;

      @media screen and ( max-width: 900px ) {
        width: auto;
      }

      .go-back {
        background-color: $very_light_gray;
        &:hover {
          background-color: $secondary_button_color_hover;
        }
      }

      #changePasswordButton {
        padding: 9px 40px 10px;
        background-color: $very_light_gray;
        color: $cancel_button_text_color;
      }

      .heading {
        margin-bottom: $standard_space * 2;
      }

      .header-left {
        color: $primary_button_color;
      }

      label {
        font-weight: $font_weight_bold;
      }

      a {
        text-decoration: none;
        font-weight: $font_weight_bold;
        color: $cancel_button_text_color;
      }

      a:hover {
        font-weight: $font_weight_bold;
        color: $cancel_button_text_color_hover;
      }

      p {
        margin: 0;
        padding: 0;
        color: $dark_accent_color;
        font-weight: $font_weight_bold;
      }

      .editProfileInput {
        background-color: $white;
        height: 2.5rem;
        font-size: 0.9rem;
        width: 100%;
        max-width: 550px;
        border: 1px solid #d8d8d8;
        border-radius: 6px;
        @media screen and ( max-width: 900px ) {
          max-width: 200px;
        }
        @media screen and ( max-width: 600px ) {
          max-width: unset;
        }
      }

      .row {
        display: flex;
      }

      .row .column {
        flex: 1;
        max-width: 50%;
      }

      @media screen and ( max-width: 800px ) {
        .row {
          display: block;

          .column {
            flex: 1;
            max-width: 100%;
          }
        }
      }

      .nameRow {
        display: flex;

        @media screen and ( max-width: 600px ) {
          flex-direction: column;

          .lastNameColumn {  
            margin-left:0 !important; 
            margin-right:0 !important; 
          }
        }
      }

      .nameRow > .firstNameColumn {
        flex: 1 1 auto;
        min-width: 150px;
        max-width: 100%;
      }

      .nameRow > .lastNameColumn {
        flex: 1 1 auto;
        min-width: 150px;
        max-width: 100%;
        margin-left: $standard_space;
      }

      .phoneNumberExampleColumn {
        padding-left: 5px;
      }
      
      @media screen and ( max-width: 600px ) {
        .phoneNumberRow, #phoneNumber {
          max-width: unset;
        }
      }

      .multiselect__tags, #phoneNumber {
        background: $white;
        border: 1px solid #d8d8d8;
        border-radius: 6px;
      }

      #contact-admin {
        margin-top: $standard_space;

        .contact {
          margin-top: $standard_space*2;
        }
      }

      .change-password {
        max-width: 200px;
        @include secondary-button-style();
        font-size: .8rem;
        text-transform: none;
      }

      .button-section {
        margin-top: $standard_space*2;
        margin-bottom: 0px;
        display: flex;
        justify-content: flex-end;
      }

      .submit,
      .cancel {
        max-width: 200px;
        font-size: .9rem;
        text-transform: none;
      }

      .cancel {
        background: none !important;
        border: none;
        padding: 0 !important;
        font-weight: normal;
        font-size: .9rem;
        text-decoration: underline;
        cursor: pointer;
        margin-right: -40px;
        color: $dark_accent_color;
      }
    }
  }
