﻿//
// Font Awesome
// v.6.1.2

$fa-font-path: $AZURE_CDN_FONTS_FONTAWESOME;

// Similar to Font Awesomes `@mixin fa-icon` minus line-height.
@mixin fa-icon-no-line-height {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-family: 'Font Awesome 6 Pro';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
//
%fa-icon-no-line-height {
  @include fa-icon-no-line-height;
}

@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid.scss";


//
// NotoSans
//

// 300
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

// 400
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

// 500
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// 600
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

// 700
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

// 800
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

// 900
@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: NotoSans;
  src: url('#{$AZURE_CDN_FONTS_NOTOSANS}/NotoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
