@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  .buttonWrap {
    margin-bottom: $standard_space;
  }

  button {
    min-width: 300px;
  }

  #LinkFederatedUserPage {
    p {
      text-align: left;
    }

    .alertBox {
      text-align: center
    }
  }
