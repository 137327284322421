@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  select {
    background: url(@/assets/down.png) 96% / 15% no-repeat $light_gray;
    background-size: 20px;
  }

  #mainPageWrap { 
    display:flex;
    flex-direction:column;
    flex:2;
  }
