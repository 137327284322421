@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

#StartupPage {
    background: $white;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 1000;
}
