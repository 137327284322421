@import "variables";


// This file contains css that is shared by both the vue app and the azure templates.
// Changes made here should be tested in both locations

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-color: $white;
  font-family: $zbEdgeFont;
  color: $primary_text;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

h1, h2, h3, h4, h5 {
  margin-top: 0;
  padding-top: 0;
  color: $dark_accent_color;
  font-weight: bold;
  margin-bottom: calc($standard_space/2);
}

h1 { font-size:1.6rem; line-height: 2.0rem;  }
h2 { font-size:1.4rem; line-height: 2.0rem; }
h3 { font-size:1.3rem; line-height: 2.0rem; }
h4 { font-size:1.2rem; line-height: 2.0rem; }
h5 { font-size:1.1rem; line-height: 2.0rem; }

p { margin-bottom: calc($standard_space/2); }

a {
  color: $primary_color;

  &:hover {
    color: $primary_color_active;
  }
}

.error {
  color: $error_color;
}

button {
  background-color: $primary_button_color;
  color: $white;
  border-radius: $standard_space;
  min-height: 40px;
  min-width: 200px;
  font-weight: $font_weight_600;
  font-size: 16px;
  line-height: 1.375;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  border-color: $primary_button_color;
  padding: 9px 40px 10px;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background-color: $primary_button_color_hover;
    color: $white;
    border-color: $primary_button_color_hover;
  }

  &:disabled {
    background-color: $primary_button_color_disabled;
    color: $white;
    border-color: $primary_button_color_disabled;
  }
}

#logoWrap {
  font-weight: $font_weight_bold;
}

#logoEdgeWrap {
  font-style: italic;
}

#logoEWrap {
  color: $primary_color;
}

.centeredContentBox {
  padding: ($standard_space*2);
  margin: ($standard_space*2) auto;
  max-width: 600px;
  background: $white;
  border-radius: $area_border_radius;

  @media screen and ( max-width: 800px ) {
    padding-left:4%;
    padding-right:4%;
    margin-left: 4%;
    margin-right: 4%;
  }
  @media screen and ( max-width: 600px ) {
    padding-left:2%;
    padding-right:2%;
    margin-left: 2%;
    margin-right: 2%;
  }
}

input {
  color: $primary_text;
  background: $very_light_gray;
  border-radius: $element_border_radius;
  border: 2px solid $gray;
  @include input-style(1.2rem, 10vw);
}

input[type=text] :not(.passwordInput) {
    min-width: 40px;
}

input[type=checkbox] {
  margin-right:10px;
}