@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  .multiselect, multiselect--active {
    max-height: $standard_space * 2;
  }

  .multiselect__option--highlight {
    background: $primary_button_color;
  }

  .multiselect__content {
    min-width: 100%;
    width: 6rem;
  }

  .multiselect__single {
    white-space: nowrap;
  }

  .multiselect__content-wrapper{
    min-width: 100%;
    width: 8rem;
  }

  .option__image {
    max-width: 24px;
  }

  .phoneNumberLabelRow {
    display: flex;
    max-width: 350px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 1;
    min-width: 150px;
    max-width: 175px;
    margin-right: 4px;
  }

  .phoneNumberLabelRow > .phoneNumberLabelColumn {
    flex: 1 1 2;
  }

  .phoneNumberRow {
    max-height: $standard_space * 2;
    display: flex;
    max-width: 350px;
  }

  .phoneNumberRow > .phoneCountryCodeColumn {
    flex: 1 1 1;
    min-width: 9rem;
    max-width: 9rem;
    margin-right: 4px;

    .countryCode {
      color: $primary_text;
    }

    .ext {
      font-size: 0.8rem;
      color: $faint_text;
      padding-left: 2px;
    }
  }

  .phoneNumberRow > .phoneNumberColumn {
    width: 100%;

    @media screen and ( max-width: 500px ) {
      
      input {  
        max-width: 150px;
      }
    }
  }
