@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

.dashboard {

  // Put all default and desktop 1200px+ view styles here  

  background-image: url('/public/img/zbedge-diamonds-background.png');
  background-size: cover;
  background-position: center;
  //min-height: calc(100vh - 50px);
  display:flex;
  flex:2;
  flex-direction:column;
  justify-content:flex-start;

  #MainDashboard {
    width:$maxInnerPageWidth;
    margin:auto;
	display: flex;
    flex:2;
	flex-direction: column;
	align-items: center;
    padding-inline:0;
  }

  .sectionHeader {
	padding-top: $standard_space;
    padding-bottom:4px;
    margin-bottom: 1rem;
	align-self: flex-start;
    border-bottom:1px solid $medium_light_gray;
  }

  .myApps { padding-inline:0; }

  .myApps, .moreApps {
    align-content: space-between;
    width:100%;

    .appsRow, .moreAppsRow {
      display:flex; 
      align-content: flex-start; 
      justify-content: flex-start; 
      flex-wrap: wrap;
      width: auto;
      column-gap: 1.9%;
    }

    .AppListItem, .MoreAppListItem  {
      width: 31.9%;
      margin-bottom:$standard_space;
      &:nth-child(3n+3) { margin-left:auto; }
    }
  }

  .moreApps {
    background: $white;
    padding-inline: 2%;
    width:100%;
  }


  // Smaller Screensizes, becoming incrementally smaller, only override what's changing 

  @media screen and (max-width: 1250px) {

    #MainDashboard { 
        padding-inline:0; 
        width:100%; 
        max-width:unset; 
    }

    .myApps { padding-inline:2%; }

    .myApps, .moreApps {
      column-gap: 2%;


      .moreAppsRow, .appsRow  {
        column-gap: 2%;
        justify-content:space-between;
      }

      .AppListItem, .MoreAppListItem  {
        width: 49%;
        margin-left:unset;
        &:nth-child(3n+3) { margin-left:unset; }
      }
    }

    .moreApps {
      flex:2;
    }
  }

  @media screen and ( max-width: 660px ) {
    .dashboard {
      min-height: calc(100vh - 76px);
    }
  }

  @media all and (max-width: 575px) {
    .moreApps,
    .myApps { 
      justify-content: center;
      display:block; 
      .moreAppsRow, .appsRow  {
        column-gap: 2%;
        justify-content: center;
        display:block; 
        .MoreAppListItem,
        .AppListItem { 
          width: auto;
          display:block; 
        }
      }
    }
  }


}
