.password, .confirm-id {
  h1 {
    margin-bottom: 1em;
  }

  p {
    text-align: left;
  }
  
  .alertBox {
    text-align: center
  }
  
  form {
    margin: 0 auto;
  }

  form {
    input {
      width: 100%;
    }

    button {
      text-align: center;
    }
  }
}

.buttonWrap {
  margin-bottom: $standard_space;
}

form {
  .passwordInput{
    .fa-eye, .fa-eye-slash {
      margin-left: -30px; 
      cursor: pointer;
    }
  }
}