@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

    .buttonWrap {
        margin-bottom: $standard_space;
    }

    .back-section {
        position: relative;

        .back-section-inner {
            position: absolute;
            top: 15px;
            left: -80px;
            cursor: pointer;
        }

        @media screen and ( max-width: 900px ) {
            .back-section-inner {
                display: none;
            }
        }

        .fa-angle-left {
            font-size: 18pt;
            color: $dark_accent_color;
        }
    }

    button {
        min-width: 350px;
    }

    #PendingInvitesPage {
        display: flex;

        p {
            text-align: left;
        }

        .alertBox {
            text-align: center;
        }

        input {
            height: 2.5rem;
        }
    }

    ul#matchingInvites {
        list-style: none;
        margin: $standard_space 0;
        padding: 0;
        text-align: left;
        border: 1px solid $gray;
        background: $very_light_gray;

        li {
            padding: $standard_space/2;
            margin: 0;
            border-bottom: 1px solid $gray;
            cursor: pointer;

            &:hover {
                background: #ededed;
            }

            &:last-child {
                border-bottom: 0 none;
            }
        }
    }
