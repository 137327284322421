﻿@import "variables";

footer {
    background-color: $footer_background_color;
    color: $white;
    font-size: 13px;
    margin-top: auto;
    display: flex;
    line-height: $footer_height;
    height: $footer_height;
    width: 100%;
    z-index: 2;
    overflow-y: hidden;
    justify-content: flex-start;
    text-align: left;

    #footerInner {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        display: flex;
    }

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $footer_link_color_hover;
        }
    }
}

@media screen and ( max-width: 660px ) {
  footer {
    height: 76px;
    justify-content: center;

    #footerInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 20px;
        justify-content: center;
    }
    
    .footerSpace {
        display: none;
    }
    
    .footerSection {
        height: $standard_space;
    }
  }
}

.mobileAppOnly {
    display: none;
}

.showMobile {
    span.websiteOnly {
        display: none;
    }

    .mobileAppOnly {
        display: block;
    }

    span.mobileAppOnly {
        display: inline;
    }
}
