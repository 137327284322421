@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

    #RegistrationPoliciesPage {
        text-align: left;

        .centeredContentBox {
            background-color: $light_gray;
            width: 80%;
            max-width: $maxInnerPageWidth;

            #userPolicyAgree {
                margin-top: $standard_space * 2;
            }
        }

        .policy-type-section {
            margin-bottom: $standard_space;
        }

        .policy-header {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            img {
                height: 23px;
                margin: 5px;
            }
        }

        @media screen and (max-width: 600px) {
            .centeredContentBox {
                margin: 0px;
                padding-top: 0px;
                width: 100%;
            }

            #userPolicyAgree {
                white-space: nowrap;
            }

            label[for=userPolicy] {
                text-wrap: wrap;
            }

            .go-back {
                float: inline-start;
                min-width: 100px;
                margin-right: 5px;
            }
        }
    }
