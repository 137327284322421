@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

	#PreLoginPage {
		background: $light_gray;
		background-image: url('/public/img/zbedge-login-background.png');
		background-size: cover;
		background-position: center;
		min-height: 100% !important;
		display: flex;
		flex: 2;
		flex-direction: column;
		justify-content: flex-start;

		#AzureTemplatePageInner {
			display: flex;
			width: 100%;
			justify-content: space-evenly;
			flex: 2;

			#titleSection {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				align-items: stretch;
				margin: 0;
				padding: 40px 4% 40px 4%;
				text-align: center;
				width: 50%;
				box-sizing: border-box;

				#logo {
					max-width: 100%;
				}
			}

			#loginSection {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				width: 50%;
				box-sizing: border-box;
				padding-inline:20px;

				#loginBox {
					padding-block: 40px;
					display: flex;
					flex-direction: column;
					background: #fff;
					width: auto;
					min-width: 550px;
					max-width: 600px;
					padding-inline: 40px;
					border-radius: 40px;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .2509803922);
					margin-inline: auto;

					#loginBoxInner {
						margin-inline: auto;
						width: 100%;
						box-sizing: border-box;
					}

					#welcomeText {
						font-weight: 700;
						font-size: 36px;
						font-weight: bold;
						margin-bottom: 40px;
						text-align: left;
					}

					#newUserStartHere {
						color: #3b77c4 !important;
						font-weight: bold;
						font-size: .9rem;
						display: block;
						text-decoration: none;
						width: 100%;

						&:hover, &:focus {
							color: #80C0E4 !important;
						}
					}

					label {
						font-weight: bold;
					}

					.button-section {
						text-align: right;

						button {
							min-width: auto;
						}
					}
				}
			}
			// Keep styling in sync with the Login.html / Azure b2c login page styling.
			select, input[type=text], input[type=email] {
				min-width: 180px;
				width: 100%;
				max-width: unset;
				height: 3rem;
			}


			@media all and (max-width:1200px) {
				flex-direction: column;
				justify-content: flex-start;

				#titleSection {
					padding: 40px 2% 20px 2%;
					width: auto;
				}

				#loginSection {
					display: block;
					justify-content: start;
					width: auto;

					#loginBox {
						min-width: unset;
						width: 600px;
						margin-inline: auto;

						.button-section {
							text-align: center;

							button {
								min-width: 66%;
							}
						}
					}
				}
			}


			@media all and (max-width:660px) {

				#titleSection {
					#logo {
						max-width: 60%;
					}
				}

				#loginSection #loginBox {
					width: auto !important;
					margin-inline: 4%;
					padding-inline: 4%;
					border-radius: 16px;

					#loginBoxInner {
						max-width: auto;
						width: 100%;
						margin-inline: 0;

						.button-section {
							button {
								min-width: auto;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}


