@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  #maintenance-page {
    font-size: $font_size_button_text;

    .centeredContentBox {
      padding-top:40px;
      padding-bottom:40px;
    }

    svg {
        display: block;
        min-width:100px;
        min-height:100px;
        margin:auto;
        margin-bottom: 20px;
    }
  }
