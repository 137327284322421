@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  #PolicyPage {
    text-align: left;
    font-size: (1200px / $maxInnerPageWidth) * 1rem;
    padding-left: (1200px / $maxInnerPageWidth) * 5%;
    padding-right: (1200px / $maxInnerPageWidth) * 5%;
    padding-top: (1200px / $maxInnerPageWidth) * 5%;

    .centeredContentBox {
      width: 80%;
      max-width: $maxInnerPageWidth;
      background: none;
      padding: 0;
    }

    h4 {
      color: #000000;
    }
  }

  @media screen and ( max-width: 960px ) {
    #PolicyPage {
      font-size: (960px / $maxInnerPageWidth) * 1.25rem;
      padding-left: (960px / $maxInnerPageWidth) * 5%;
      padding-right: (960px / $maxInnerPageWidth) * 5%;
      padding-top: (960px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (960px / $maxInnerPageWidth) * 5%;
        margin-top: (960px / $maxInnerPageWidth) * 5%;
        margin-left: 0;
        width: 100%;
        max-width: $maxInnerPageWidth;
      }
    }
  }

  @media screen and ( max-width: 720px ) {
    #PolicyPage {
      font-size: (720px / $maxInnerPageWidth) * 1.5rem;
      padding-left: (720px / $maxInnerPageWidth) * 5%;
      padding-right: (720px / $maxInnerPageWidth) * 5%;
      padding-top: (720px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (720px / $maxInnerPageWidth) * 5%;
        margin-top: (720px / $maxInnerPageWidth) * 5%;
      }
    }
  }

  @media screen and ( max-width: 600px ) {
    #PolicyPage {
      font-size: (600px / $maxInnerPageWidth) * 1.5rem;
      padding-left: (600px / $maxInnerPageWidth) * 5%;
      padding-right: (600px / $maxInnerPageWidth) * 5%;
      padding-top: (600px / $maxInnerPageWidth) * 5%;

      .centeredContentBoxMedia {
        padding-top: (600px / $maxInnerPageWidth) * 5%;
        margin-top: (600px / $maxInnerPageWidth) * 5%;
      }
    }

    .showMobile {
      h4 {
        font-size: (600px / $maxInnerPageWidth) * 2rem;
        color: #000000;
      }

      a {
        word-wrap: break-word;
      }
    }
  }
