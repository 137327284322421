﻿@use "sass:math";
@import "variables";
@import "base";
@import "font-face";
@import "footer";

#app {
    background: $light_gray;
    font-family: $zbEdgeFont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primary_text;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.button-section {
  margin-top: calc($standard_space*2);
  margin-bottom: calc($standard_space*2);
}

.button-section-right {
    margin-top: calc($standard_space*2);
    overflow: auto;
}

.button-section-center {
    margin-top: calc($standard_space*2);
    overflow: auto;
    text-align:center;
}

.go-back {
    @include secondary-button-style();
    background-color: $white;
    color: $primary_button_color;
    margin-right: 2rem;
    &:hover {
        color: $primary_button_color;
    }
}

.button-secondary {
    @include secondary-button-style();
}

.button-right {
    float: inline-end;
}

@media screen and (max-width: 600px) {
  .button-section-right {
      margin-top: calc($standard_space*2);
      overflow: auto;
  }
  
  .button-right {
      min-width: 150px;
      max-width: 40%;
      width: 40%;
      padding: 10px 0px 10px;
  }

  .go-back {
    min-width: 120px;
    margin-right: 5%;
  }
}

.label-bold {
    font-weight: bold;
}

.header-left {
    text-align: left;
}

select {
    min-width: 150px;
    padding: 8px 40px 8px 8px;
    font-size: 16px;
    border: 1px solid $light_gray;
    border-radius: $element_border_radius;
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
}

select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $faint_text;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $faint_text;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $faint_text;
}

.max-page-width {
  max-width: $maxInnerPageWidth;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2%;
  padding-right: 2%;
}

.max-page-width-nav {
    max-width: none;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
}

.bottomSpace {
    margin-bottom: $standard_space !important;
}

.bottomSpaceDouble {
    margin-bottom: calc($standard_space*2) !important;
}

.alertBox {
    background: $pale_yellow;
    border-radius: $element_border_radius;
    padding: 4px;
    font-weight: $font_weight_600;
    text-align: center;
}

.shared-page {
  text-align: center;
  padding: calc($standard-space * 2);

  @media screen and ( max-width: 800px ) {
    padding: ($standard_space);
  }

  @media screen and ( max-width: 600px ) {
    padding-left: 2%;
    padding-right: 2%;
  }

  #logoWrap {
    font-size: 1.6rem;
  }
}

.textLarge {
    font-size: 1.3rem;
}

.center { text-align:center; }

.section {
  display: block;
  text-align: left;
  margin-bottom: $standard_space;
}

.label, label { 
  margin-bottom:2px; 
  font-size:.9rem;
}

.optional {
  font-style: italic;
  color:$faint_text;
}

.hide {
  display: none;
}


@media screen and ( max-width: 1920px ) {
  .shared-page #logoWrap {
    font-size: 2.0rem;
  }

  .textLarge {
    font-size: 1.1rem;
  }

  button {
    font-size: 1.2rem;
  }

  select {
    font-size: 16px;
  }

  input {
    @include input-style(1.0rem, 18vw);
  }
}

@media screen and ( max-width: 1680px ) {  
  .shared-page #logoWrap {
    font-size: 1.8rem;
  }

  .textLarge {
    font-size: 1rem;
  }

  button {
    font-size: 1.1rem;
  }

  select {
    font-size: 16px;
  }

  input {
    @include input-style(1.02rem, 19vw);
  }
}

@media screen and ( max-width: 1440px ) {
  .label,
  .intro {
    font-size: 0.98rem;
  }

  input {
    @include input-style(1rem, 20vw);
  }
}

@media screen and ( max-width: 1200px ) {
  .label,
  .intro {
    font-size: 0.96rem;
  }

  input {
    @include input-style(0.98rem, 21vw);
  }
}

@media screen and ( max-width: 960px ) {
  .label,
  .intro {
    font-size: 0.94rem;
  }

  input {
    @include input-style(0.96rem, 22vw);
  }
}

@media screen and ( max-width: 720px ) {
  .label,
  .intro {
    font-size: 0.92rem;
  }

  input {
    @include input-style(0.94rem, 23vw);
  }
}

@import "forms";