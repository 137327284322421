@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  .a-span {
    background: none !important;
    border: none;
    cursor: pointer;
    font-weight: $font_weight_bold;
    color: $cancel_button_text_color;
  }

  .a-span:hover {
    background: none !important;
    border: none;
    cursor: pointer;
    font-weight: $font_weight_bold;
    color: $cancel_button_text_color_hover;
  }

  #confirmInput {
    height: 2.5rem;
  }
