@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  .buttonWrap {
    margin-bottom: $standard_space;
  }

  #RedeemInvitePage {
    margin-left: auto;
    margin-right: auto;
    padding-left:4%;
    padding-right:4%; 

    p {
      text-align: left;
    }

    .alertBox {
      text-align: center
    }

    .button-secondary { 
      margin-right: 2rem;
    }

    .centeredContentBox {
      margin-left: auto;
      margin-right: auto;
    }

    .phoneNumberRow > .phoneCountryCodeColumn {
      min-width: 150px;
      max-width: 150px;
    }

    .multiselect__tags, #phoneNumberInput {
      background: $very_light_gray;
      border: 0px;
      border-radius: 6px;
    }

    .multiselect__single {
      background: $very_light_gray;
    }
  }

  #createAccountForm {
    margin: auto;
    width: 100%;
  }

  input, .passwordInput {
    width: 100%;
  }

