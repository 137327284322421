@import "@/scss/variables.scss";
                         @import "@/scss/shared.scss";

  #RecoverPinPage {
    .back-section {
      position: relative;

      .back-section-inner {
        position: absolute;
        top: 15px;
        left: -80px;
        cursor: pointer;
      }

      @media screen and ( max-width: 900px ) {
        .back-section-inner {
          display: none;
        }
      }

      .fa-angle-left {
        font-size: 18pt;
        color: $dark_accent_color;
      }
    }

    div {
      text-align: center;
    }

    p {
      text-align: center;
    }

    table, th, tr, td {
      table-layout: fixed;
      width: 100%;
      height: 100%;
      border: 1px solid black;
      text-align: center;
      font-size: 0.8rem;
      word-break: break-word;
    }

    .recoverPinButton > button {
      all: unset;
      background-color: #b41e8e;
      color: #fff;
      font-weight: 600;
      height: 100%;
      width: 100%;
      cursor: pointer;

      &:hover {
        background-color: #901872;
        color: #fff;
        border-color: #901872;
      }

      &:disabled {
        background-color: #e8bbdd;
        color: #fff;
        border-color: #e8bbdd;
      }
    }
  }
